import React from 'react';
import './photoGallery.css'; // Import the CSS file for styling

const PhotoGallery = ({ imageUrls, setSelectedImageId}) => {
  const [_selectedImageId,_setSelectedImageId] = React.useState(0);

  const handleImageSelection = () => {

  }

  React.useEffect(
    ()=>{
      // setting selected image ID in the parent component
      console.log("Selected image with id of: " + _selectedImageId)
      setSelectedImageId(_selectedImageId);
    }
  ,[_selectedImageId]);

  return (
    <div className="photo-gallery">
      {imageUrls.map((url, index) => (
        <div key={index} className={`gallery-item ${(index == _selectedImageId) && "selected-item"}`} onClick={()=>{(index === _selectedImageId) ? _setSelectedImageId(null) : _setSelectedImageId(index)}}>
          <img src={url} alt={`Gallery item ${index}`} />
        </div>
      ))}
    </div>
  );
};

export default PhotoGallery;

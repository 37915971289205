import React, { useState } from "react";
import "./styles.css";
import SignInForm from "./SignIn";
import SignUpForm from "./SignUp";
import AuthPage from "./Auth";
import ProfilePage from "./ProfilePage";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

export default function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<AuthPage/>}/>
      <Route path="/signUp" element={<SignUpForm/>}/>
      <Route path="/signIn" element={<SignInForm/>}/>
      <Route path="/profile" element={<ProfilePage/>}/>
    </Routes>
    </Router>

  );
}

import React, { useState, useRef, useEffect} from 'react';
import VideoRecorder from './video';
import PhotoGallery from './photoGallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShareNodes} from '@fortawesome/free-solid-svg-icons';
import { faShareSquare, faArrowAltCircleDown, faUser as faUserReg} from '@fortawesome/free-regular-svg-icons';
// import {faCloudArrowDown} from '@fortawesome/free-solid-svg-icons';
// import {ReactComponent as indeedLogo} from "./assets/svg/indeed.svg";
import indeedLogo from "./assets/svg/indeed.svg";
import CustomColorPalette from './CustomColorPalette';
import { useLocation } from 'react-router-dom';


function ProfilePage() {

    const [showVideo1, setShowVideo1] = useState(true);
    const [showVideo2, setShowVideo2] = useState(true);
    const [showVideo3, setShowVideo3] = useState(true);
    const [video1Url, setVideo1Url] = useState(null);
    const [video1File, setVideo1File] = useState(null);
    const [video2Url, setVideo2Url] = useState(null);
    const [video2File, setVideo2File] = useState(null);
    const [video3Url, setVideo3Url] = useState(null);
    const [video3File, setVideo3File] = useState(null);
    const [profilePicUrl, setProfilePictureUrl] = useState(null);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [video1Transcription, setVideo1Transcription] = useState(null);
    const [video2Transcription, setVideo2Transcription] = useState(null);
    const [video3Transcription, setVideo3Transcription] = useState(null); 
    const [shareableUrl, setShareableUrl] = useState("https://talent-ai/profile");
    const [selectedImageId, setSelectedImageId] = useState();
    const [portfolioImageUrls, setPortfolioImageUrls] = useState([]);
    const [portfolioImageFiles, setPortfoliioImageFiles] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState('white');
    const [foregroundColor, setForegroundColor] = useState('black');
    // const foregroundColor = "black";

    const profilePicInputRef = useRef();
    const video1RecorderRef = useRef();
    const video2RecorderRef = useRef();
    const video3RecorderRef = useRef();
    const uploadVideo1Ref = useRef();
    const uploadVideo2Ref = useRef();
    const uploadVideo3Ref = useRef();
    const addPortfolioImageRef = useRef();
    const video1TextRef = useRef();
    const video2TextRef = useRef();
    const video3TextRef = useRef();

    const location = "New York, NY";

    const [fullName , setFullName] = useState("");
    const [position, setPosition] = useState("");
    const [openTo, setOpentTo] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [indeed, setIndeed] = useState("");


    function toggleShowVideo1() {
        const newState = !showVideo1;
        setShowVideo1(newState)
        console.log("Toggling show video 1")
    }

    function uploadVideo1(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setVideo1File(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const videoUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          video1RecorderRef.current.extSetVideoUrl(videoUrl); // Set preview URL
          console.log(`Video 1 Url: ${video1Url}}`)
        }
    }

    function toggleShowVideo2() {
        const newState = !showVideo2;
        setShowVideo2(newState)
        console.log("Toggling show video 3")
    }

    function uploadVideo2(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setVideo2File(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const videoUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          video2RecorderRef.current.extSetVideoUrl(videoUrl); // Set preview URL
          console.log(`Video 2 Url: ${video2Url}}`)
        }
    }

    function toggleShowVideo3() {
        const newState = !showVideo3;
        setShowVideo3(newState)
        console.log("Toggling show video 3")
    }
    
    function uploadVideo3(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setVideo3File(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const videoUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          video3RecorderRef.current.extSetVideoUrl(videoUrl); // Set preview URL
          console.log(`Video 3 Url: ${video3Url}}`)
        }
    }

    // todo:
    function changeProfilePicture() {
        profilePicInputRef.current.click();
    }

    function handleProfilePicUpload(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setProfilePicFile(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const imageUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          setProfilePictureUrl(imageUrl); // Set preview URL
        }
    }

    function handlePortfolioImageUpload(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setPortfoliioImageFiles([...portfolioImageFiles,selectedFile]);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const imageUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          setPortfolioImageUrls([...portfolioImageUrls,imageUrl]); // Set preview URL
        }

    }

    function handlePortfolioImageDelete() {
        const indexToDelete = selectedImageId;
        console.log(`Deleting: ${indexToDelete}`);
        setPortfolioImageUrls([...portfolioImageUrls.slice(0, indexToDelete), ...portfolioImageUrls.slice(indexToDelete + 1)]);
        setPortfoliioImageFiles([...portfolioImageFiles.slice(0, indexToDelete), ...portfolioImageFiles.slice(indexToDelete + 1)]);

    }

    const handleShare = async () => {
        const shareData = {
          title: 'Check this out!',
          text: 'I found this interesting:',
          url: shareableUrl,
        };
    
        try {
          await navigator.share(shareData);
          console.log('Content shared successfully');
        } catch (err) {
          console.log('Error sharing:', err);
        }
      };


    function getUserDetails() {

    }

    function saveData() {
        console.log("Video 1 transcripttion: " + video1Transcription)
    }
 
    // Function to copy text to clipboard
    const copyTextToClipboard = async () => {
        try {
        await navigator.clipboard.writeText(shareableUrl); // Use Clipboard API to copy text
        //   setCopySuccess('Text copied to clipboard!'); // Update success message
        } catch (err) {
        //   setCopySuccess('Failed to copy text.'); // Handle error
        }
    };

    //To be executed on first launch
    useEffect(async () => {
        console.log("First time execution");
        // Pull user data on first launch
        setFullName("Zevi Arem");
        setPosition("Digital Marketer");
        setCity("New York");
        setState("NY");
        setPhone("+1 204 384823993");
        setEmail("talentai@gmail.com");
        setLinkedIn("https://www.linkedin.com/in/simon-nsah-05169a221/");
        setIndeed("https://www.indeed.com/profile/");
        // setForegroundColor("red");

        return ()=>{}

    }, []);


    const transcribeVideo = async (videoFile, setVideoTranscript) => {

        if(videoFile) {
    
        }else {
          console.log("No video file")
          return;
        }
        const formData = new FormData();
        formData.append('file', videoFile);
    
        try {
          console.log('Uploading...');
          const response = await fetch('http://localhost:5000/api/transcribe', {
            method: 'POST',
            body: formData,
          });
    
          if (response.ok) {
            console.log('Upload successful!');
          } else {
            console.log(`Upload failed: ${response.statusText}`);
          }
    
          const data = await response.json();
          // videoTextRef.current.value = "hello world";
          setVideoTranscript(data.transcription);
          console.log(data.transcription)
    
        } catch (error) {
          console.log(`Upload error: ${error.message}`);
        }
      };


    return <div>

        {/* header with color palette */}
        <div style={{position: "sticky", top: "0px", zIndex: "1000", backgroundColor: "#f6f5f7", padding: "20px"}}>
            <div style={{display: "flex", position: "relative", justifyContent: "end"}}>
                <CustomColorPalette onColorChange={(newColor) => setBackgroundColor(newColor)} title="Background"/>
                <div style={{width: "40px"}}/>
                <CustomColorPalette onColorChange={(newColor) => setForegroundColor(newColor)} title='Foreground'/>
            </div>
        </div>

        <div style={{ display: "flex", borderRadius: "20", justifyContent: "center", margin: "20px"}}>
        <div onClick={changeProfilePicture}>
        {(profilePicUrl != null) ? <img src={profilePicUrl} height="100" width="100" style={{ borderRadius: "50%", objectFit: "cover" }} />
            : <FontAwesomeIcon icon={faUserReg} size="5x" height="100" width="100" style={{ borderRadius: "50%", objectFit: "cover" }}/>}
        </div>


        <input type="file" onChange={handleProfilePicUpload} multiple ref={profilePicInputRef} style={{display: "none"}}/>

            <div style={{ marginLeft: "10px" }}>
                <h5 style={{ margin: "0px" }}>{fullName}</h5>
                <h7 style={{ margin: "0px" }}>{position}</h7>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="location-icon"
                    >
                        <path d="M21 10c0 7-9 13-9 13S3 17 3 10a9 9 0 0 1 18 0z" />
                        <circle cx="12" cy="10" r="3" />
                    </svg>
                    <p>{location}</p>

                </div>
                <div style={{display: "flex", alignItems: "center" ,justifyContent: "space-evenly", backgroundColor: 'lightgray', height: '30px', borderRadius: "15px" }}>
                    {indeed && <a href={indeed} target="_blank" className="social">
                         <img src={indeedLogo} color="black"/>
                    </a>}

                    {email && <a href={"mailto:" + email} target="_blank" className="social">
                        <i class="fas fa-envelope"></i>
                    </a>}

                    {phone && <a href={"tel:" + phone} target="_blank" className="social">
                        <i class="fas fa-phone"></i>
                    </a>}

                    {linkedIn && <a href={linkedIn} target="_blank" className="social">
                        <i className="fab fa-linkedin-in" />
                    </a>}
                </div>
                {/* Availability container */}
                {/* <div className="hide-on-mobile" style={{display: "flex", alignItems: "center"}}>
                    <p style={{paddingRight: "10px", alignItems: "center"}}>Open to</p>
                    <p style={{ backgroundColor: "green", color: "white", padding: "10px", borderRadius: "30px", }}>{openTo}</p>
                </div>  */}
            </div>
        </div>


        {/* copy shareable link */}
        <div style={{display: "flex", alignItems: "center", backgroundColor: foregroundColor, height: '50px', width: '300px', borderRadius: "15px" , margin: "0 auto"}}>
                    <div style={{width: "80%", marginLeft: "10px", overflow: "auto"}}>
                    <h3 style={{overflow: "hidden", textOverflow: "ellipsis" , color: "white", whiteSpace: "nowrap"}}>{shareableUrl}</h3>
                    </div>
                    <div onClick={copyTextToClipboard} title="copy" style={{ display: "flex", alignItems: "center" ,position: "relative", width: "20%" , height: "100%"}}>
                            <a href="#" style={{position: "absolute" , right: "10px", margin: "auto 0"}}>
                                <i class="fa-regular fa-copy fa-xl" style={{color: backgroundColor, opacity: 1}}></i>
                            </a>
                    </div>
                </div>

        {/* Actions */}
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                
                    <div onClick={handleShare} style={{display: "inline"}}>
                        <FontAwesomeIcon icon={faShareSquare} size='2x' style={{color: foregroundColor}}/>
                    </div>
                    <div style={{width: "5%"}}/>
                    <a href="#">
                        <FontAwesomeIcon icon={faArrowAltCircleDown} size='2x' style={{color: foregroundColor}} />
                    </a>
        </div>
        

         {/* Video transcript box 1*/}
         <div className='video-transcript-container'>
            <div className='header' style={{backgroundColor: foregroundColor}}>
            <h2>Tell us more about yourself</h2>
            <div  className='actions' style={{display: "flex" ,alignItems: "center"}}>
                    <div onClick={()=>{uploadVideo1Ref.current.click()}}>
                        <i class="fa-solid fa-upload fa-2xl"></i>
                    </div>
                    <input type="file" onChange={uploadVideo1} multiple ref={uploadVideo1Ref} style={{display: "none"}}/>


                    <div onClick={toggleShowVideo1} style={{ display: 'inline' }}>

                        {
                            showVideo1
                                ? <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="3"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <path
                                        d="M3 3L21 21"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                </svg>
                                : <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="3"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                </svg>
                        }



                    </div>
            </div>
            </div>
            <div style={{display: showVideo1 ? "block" : "none"}} >
                <VideoRecorder videoFile={video1File} setVideoFile={setVideo1File} foregroundColor={foregroundColor} ref={video1RecorderRef}/>
                    {/* Transcribe button */}
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button onClick={() => {transcribeVideo(video1File, setVideo1Transcription)}} style={{backgroundColor: foregroundColor}}>Publish</button>
                    </div>
            </div>

            <div style={{height: '200px', overflow: "hidden"}}> 
                <textarea value={video1Transcription} ref={video1TextRef} placeholder="The Video transcript will appear here" onChange={(event)=>{setVideo1Transcription(event.target.value)}} >
                    
                </textarea>
            
            {/*<input  
                value="I am a motivated individual with a strong passion for artificial intelligence 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum....."
            
                    />*/}
            </div>
            
         </div>

        {/* Video transcript box 2*/}
        <div className='video-transcript-container'>
            <div className='header' style={{ backgroundColor: foregroundColor }}>
                <h2>Tell us more about your history</h2>
                <div className='actions' style={{ display: "flex", alignItems: "center" }}>
                    <div onClick={() => { uploadVideo2Ref.current.click() }}>
                        <i class="fa-solid fa-upload fa-2xl"></i>
                    </div>
                    <input type="file" onChange={uploadVideo2} multiple ref={uploadVideo2Ref} style={{ display: "none" }} />


                    <div onClick={toggleShowVideo2} style={{ display: 'inline' }}>

                        {
                            showVideo2
                                ? <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="3"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <path
                                        d="M3 3L21 21"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                </svg>
                                : <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="3"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                </svg>
                        }



                    </div>
                </div>
            </div>
            <div style={{ display: showVideo2 ? "block" : "none" }} >
                <VideoRecorder foregroundColor={foregroundColor} videoFile={video2File} setVideoFile={setVideo2File} ref={video2RecorderRef} />
                {/* Transcribe button */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button style={{backgroundColor: foregroundColor}} onClick={() => { transcribeVideo(video2File, setVideo2Transcription) }}>Publish</button>
                </div>
            </div>

            <div style={{ height: '200px', overflow: "hidden" }}>
                <textarea value={video2Transcription} ref={video2TextRef} placeholder="The Video transcript will appear here" onChange={(event) => { setVideo2Transcription(event.target.value) }} >

                </textarea>

                {/*<input  
                value="I am a motivated individual with a strong passion for artificial intelligence 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum....."
            
                    />*/}
            </div>

        </div>

        {/* Video transcript box 3*/}
        <div className='video-transcript-container'>
            <div className='header' style={{ backgroundColor: foregroundColor }}>
                <h2>Tell us more about your education</h2>
                <div className='actions' style={{ display: "flex", alignItems: "center" }}>
                    <div onClick={() => { uploadVideo3Ref.current.click() }}>
                        <i class="fa-solid fa-upload fa-2xl"></i>
                    </div>
                    <input type="file" onChange={uploadVideo3} multiple ref={uploadVideo3Ref} style={{ display: "none" }} />


                    <div onClick={toggleShowVideo3} style={{ display: 'inline' }}>

                        {
                            showVideo3
                                ? <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="3"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <path
                                        d="M3 3L21 21"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                </svg>
                                : <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="3"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="none"
                                    />
                                </svg>
                        }



                    </div>
                </div>
            </div>
            <div style={{ display: showVideo3 ? "block" : "none" }} >
                <VideoRecorder foregroundColor={foregroundColor} videoFile={video3File} setVideoFile={setVideo3File} ref={video3RecorderRef} />
                {/* Transcribe button */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button onClick={() => { transcribeVideo(video3File, setVideo3Transcription) }} style={{backgroundColor: foregroundColor}}>Publish</button>
                </div>
            </div>

            <div style={{ height: '200px', overflow: "hidden" }}>
                <textarea value={video3Transcription} ref={video3TextRef} placeholder="The Video transcript will appear here" onChange={(event) => { setVideo3Transcription(event.target.value) }} >

                </textarea>

                {/*<input  
                value="I am a motivated individual with a strong passion for artificial intelligence 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum....."
            
                    />*/}
            </div>

        </div>


         {/* Input fields */}
         <div style={{margin: "10px auto", width: "90%"}}>
            
            <input
            type="text"
            placeholder="City"
            name="city"
            onChange={(event)=>{setCity(event.target.value)}}
            value={city}
            />

            <input
            type="text"
            placeholder="State"
            name="state"
            onChange={(event)=>{setState(event.target.value)}}
            value={state}
            />

            <input
            type="text"
            placeholder="Position"
            name="postion"
            onChange={(event)=>{setPosition(event.target.value)}}
            value={position}
            />

            <input
            type="tel"
            placeholder="Phone"
            name="phone"
            onChange={(event)=>{setPhone(event.target.value)}}
            value={phone}

            />

            <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={(event)=>{setEmail(event.target.value)}}
            value={email}
            />

            <input
            type="url"
            placeholder="LinkedIn Profile"
            name="linkedIn"
            onChange={(event)=>{setLinkedIn(event.target.value)}}
            value={linkedIn}
            />

            <input
            type="url"
            placeholder="Indeed Profile"
            name="indeed"
            onChange={(event)=>{setIndeed(event.target.value)}}
            value={indeed}
            />
         </div>

{/* Save button */}
        <div style={{display: "flex"}}>
        <div style={{margin: "0px auto"}}>
        <button onClick={saveData}>
            Save
         </button>
        </div>
        </div>
        
        {/* Portfolio Section */}
        <div className='video-transcript-container' style={{marginBottom: "30px"}}>
            <div className='header' style={{backgroundColor: foregroundColor}}>
            <h2>Portfolio</h2>
            <div  className='actions'>
                <div style={{display: "inline"}} onClick={handlePortfolioImageDelete}>
                    <i class="fas fa-trash fa-2x" style={{margin: "0px 10px"}}></i>
                </div>
                
                <div style={{display: "inline"}} onClick={()=>{addPortfolioImageRef.current.click()}}>
                    <i class="fas fa-plus fa-2x" style={{margin: "0px 10px"}}></i>
                </div>
                
                <input type='file' onChange={handlePortfolioImageUpload}  style={{display: "none"}} ref={addPortfolioImageRef}/>
            </div>
            </div>
            <PhotoGallery imageUrls={portfolioImageUrls} setSelectedImageId={(id)=>{setSelectedImageId(id)}}/>
            
         </div>


         


    </div>;
}

export default ProfilePage;
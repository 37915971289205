import React from "react";
import {useNavigate} from "react-router-dom";
import Loader from "./loader";

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function SignUpForm() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    name: "",
    email: "",
    password: ""
  });
  const [isLoading, setLoading] = React.useState(false);
  const startLoading = () => {
      setLoading(true);
      console.log(`Starting to load: ${isLoading}`)
  }

  const stopLoading = () => {
    setLoading(false);
    console.log(`stopping to load: ${isLoading}`)
  }



  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const handleOnSubmit = evt => {
    evt.preventDefault();

    const { name, email, password } = state;
    alert(
      `You are sign up with name: ${name} email: ${email} and password: ${password}`
    );

    for (const key in state) {
      setState({
        ...state,
        [key]: ""
      });
    }
  };

  const gotoSignInPage = () => {
    const data = { page: "signIn" };
    
    navigate('/', { state: data });
    window.location.reload();
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const email = state.email;
    const password = state.password;
    const name = state.name;
    
    startLoading();
    // await delay(3000);
    try {
      const response = await fetch('http://localhost:5000/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, name }),
      });

      const data = await response.json();

      if (response.ok) {
        // Store the JWT token in localStorage or sessionStorage
        localStorage.setItem('token', data.token);

        // Redirect or update UI as needed
        console.log('Logged in successfully:', data.token);

        navigate("/profile");
      } else {
        console.error('Login failed:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    stopLoading();
  };

  return (
    <div className="form-container sign-up-container">
      <form onSubmit={handleRegister}>
        <h1>Create Account</h1>
        <div className="social-container">
          <a href="#" className="social">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-google-plus-g" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-linkedin-in" />
          </a>
        </div>
        <span>or use your email for registration</span>
        <input
          type="text"
          name="name"
          value={state.name}
          onChange={handleChange}
          placeholder="Name"
        />
        <input
          type="email"
          name="email"
          value={state.email}
          onChange={handleChange}
          placeholder="Email"
        />
        <input
          type="password"
          name="password"
          value={state.password}
          onChange={handleChange}
          placeholder="Password"
        />
        <button onClick={handleRegister}>
          <div style={{display: "flex"}}>
            <div>
              Sign Up
            </div>
            <Loader isLoading={isLoading}/>
          </div>
          </button>
        <div
            className="hide-on-desktop hover-underline"
            // id="signUp"
            onClick={gotoSignInPage} style={{color: "blue"}}
          >
            Sign In
          </div>
      </form>
      
    </div>
  );
}

export default SignUpForm;

import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';

const VideoRecorder = forwardRef((props, ref) => {
  const {videoFile, setVideoTranscript, setVideoFile, foregroundColor} = props;
  const [recording, setRecording] = useState(false);
  const [showFeed, setShowFeed] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      extSetVideoUrl : (url) => {
        setVideoURL(url);
      }
    })
  );

  const startRecording = async () => {
    setShowFeed(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true , audio: true});
      streamRef.current = stream;
      videoRef.current.srcObject = stream;
      videoRef.current.play();

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const videoFile = new File([blob], `recorded_video_${Date.now()}_${Math.floor(Math.random() * 10000)}.webm`
        , { type: 'video/webm' });
        setVideoFile(videoFile); //Set video file in the parent component.
        setVideoURL(url);
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  const stopRecording = () => {
    setShowFeed(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    setRecording(false);
  };

  return (
    <div style={{display: "relative"}}>

        <video ref={videoRef} style={{display: showFeed ? "block" : "none"}} width="100%" autoPlay/>
        {videoURL && (<video src={videoURL} style={{display: showFeed ? "none" : "block"}} controls width="100%" />)}
      {/* {showFeed ? <video ref={videoRef}  width="320" height="240" autoPlay muted /> : <video src={videoURL} controls width="320" height="240" />} */}
      {/* <video ref={videoRef}  width="320" height="240" autoPlay muted /> */}
      <div style={{width: "100%", display: 'flex'}}>

        {/* Record button */}
         <div style={{margin:"10px auto"}}>
          {!recording ? (
                <button onClick={startRecording} style={{backgroundColor: foregroundColor}} >Start Recording</button>
            ) : (
                 <button onClick={stopRecording} style={{backgroundColor: foregroundColor}}>Stop Recording</button>
            )}
        </div>

      </div>
      {/* {videoURL && (
        <div>
          <video src={videoURL} style={{display: showFeed ? "none" : "block"}} controls width="320" height="240" />
          <a href={videoURL} download="recording.webm">{showFeed ? "showFeed" : "Don't show feed"}</a>
        </div>
      )} */}
    </div>
  );
})

export default VideoRecorder;

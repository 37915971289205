import React, { useState } from 'react';
import './custom_color_palette.css'; // Import CSS file for styles

const colors = [
  'black', 'white', 'blue', 'red', 'green', 'orange',
  'purple', 'pink', 'yellow'
];

const CustomColorPalette = ({ onColorChange , title= "Background Color" , defaultColor= "black"}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    onColorChange(color);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div>
        <span>{title}</span>
      <div onClick={toggleDropdown} className="color-display" style={{ backgroundColor: selectedColor,  color: (selectedColor === "white") ? "black" : "white" }}>
        <span className="color-text">{selectedColor}</span>
      </div>
      <div className="dropdown-container">
        {isOpen && (
          <div className="dropdown-menu">
            {colors.map((color) => (
              <div
                key={color}
                className="color-option"
                style={{ backgroundColor: color, color: (color === "white") ? "black" : "white" }}
                onClick={() => handleColorSelect(color)}
              >
                {color}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomColorPalette;
